
.ApplicationTopLayer {
    background: #EBFBFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
    

}

body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    margin: 0;
}


.FillAplication{
    margin-top: 30px;
}
.file-input {
   margin: 10px;
   padding: 10px;
    height: fit-content;
  text-align: center;
  background-color: lightblue;
  border: 1px solid black;
  border-radius: 7px;
  color: black;
    
}

.ApplyFirstName{
    background: #EBFBFF;
    border: 1.4px solid #08c4f3;
    padding: 8px;
    margin: 8px;
    width: 300px;
    height: 10px;
}

.ApplyFirstName::placeholder {
    color: #08c4f3; 
}
.MobileEmail::placeholder {
    color: #08c4f3; 
}
.MobileMessage::placeholder {
    color: #08c4f3; 
}

.Upload{
    font-size: 10px;
}

.BacktoJobs{
    width: 300px;
    margin-top: 10px;
}
.NoticePeriod{
    background: #EBFBFF;
    border: 1.4px solid #08c4f3;
    padding: 8px;
    margin: 8px;
    width: 320px;
    height: 35px;
    color: #08c4f3;
}

@media screen and (min-width:767px){
    .ApplicationTopLayer {
        background: #EBFBFF;
       
       
    
    }
    .FillAplication{
        margin-top: 0px;
        
    }


    
    
    .FillAplication{
        margin-top: 20px;
    }
    .file-input {
       margin: 10px;
       padding: 10px;
        height: 20px;
      text-align: center;
      background-color: lightblue;
      border: 1px solid black;
      border-radius: 7px;
      color: black;
        
    }
    
    .Upload{
        font-size: 10px;
    }
    
    .BacktoJobs{
        width: fit-content;
        margin-bottom: 0px;
    }
    
     
}
