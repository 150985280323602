.Center{
    text-align: center;
}

.CurrentOpening-TopLayer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PostingIcon{
    height: 100px;
    margin-top: 80px;
    margin-bottom: 80px;
}
.DashBoard-TopLayer{
    display: flex;
flex-direction: column;
align-items: center;
}

.JobPosting-Form{
    text-align: justify;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border: 1px solid black;
    width: 300px;
    margin: 10px;
    
}

.JobPostingLogo{
    height: 5000px;
}

@media screen  and (min-width:767px){
    .CurrentOpening-TopLayer{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .JobPosting-Form{
        text-align: justify;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px;
        border: 1px solid black;
        width: 400px;
    }
    .JobPostingLogo{
        height: 180px;
    }
    .PostingIcon{
        height: 500px;
       
    }
}
