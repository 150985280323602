.JobItemTopLayer {
    border: solid 1px black;
    margin: 10px;
    background-color: white;
    border-radius: 7px;
    max-width: 330px;
    text-align: center;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}

.JobItemFirstLayer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;

}
.JobItemFirst-Container{
    padding: 10px;
}

.ArrowIcon {
    margin-left: 10px;
    margin-top: 5px;
    font-size: large;
}

.JobItemSecondLayer {
    font-size: 1p2x;
    background-color: rgba(198, 195, 195, 0.719);
    padding: 10px;
    border-top-left-radius:8px ;
    border-top-right-radius:8px ;
    
}

.ApplyButton {
    background-color: lightblue;
    width: fit-content;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    border: solid 1px rgba(0, 0, 0, 0.505);
}

.Description {
    text-align: initial;
}
@media screen and (min-width:767px) {
    .JobItemTopLayer {
        border: solid 1px black;
        margin: 10px;
        background-color: white;
        border-radius: 7px;
      width: 400px;
        text-align: center;
    }
    
    .JobItemFirstLayer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
    
    }
    
    .ArrowIcon {
        margin-left: 10px;
        margin-top: 5px;
        font-size: large;
    }
    
    .JobItemSecondLayer {
        font-size: 12px;
        background-color: rgba(198, 195, 195, 0.719);
        padding: 10px;
        border-top-left-radius:8px ;
        border-top-right-radius:8px ;
       
    }
    
    .ApplyButton {
        background-color: lightblue;
        width: fit-content;
        color: black;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
        border: solid 1px rgba(0, 0, 0, 0.505);
    }
    
  
}