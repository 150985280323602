.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.options-icon {
  position: fixed;
  right: 30px;
  display: flex;
  align-self: end;
  top: 10px;
  height: 30px;
  z-index: 999;
  background-color: transparent !important; 
}

.chat-icon {
  position: fixed;
  display: flex;
  align-self: end;
  bottom: 20px;
  right: 30px;
  z-index: 999;
  background-color: transparent !important; 
}

.content-container {
  flex: 1;
  overflow-y: auto;
}
