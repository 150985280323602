.JobTopLayer {
    background: #EBFBFF;
    padding: 40px;
}

.Filter-Button{
    width: fit-content;
    font-weight:100;
    background: #25AAE1;
    font-family: 'Neue Haas Grotesk Display Pro';
    height: fit-content;
    color: white;
}


.FiltersContainer{
    display: flex;
    flex-direction: column;
    color: black;
    font-size: smaller;
  
    
}
.FirstSearch-layer{
    display: flex;
    height: fit-content;
   border: 1px solid black;
    border-radius: 6px;
  
}

.SearchBar{
    text-align: center;
    border: none;
    margin: 2px;
}

.JobSearchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Jobs-Lenght{
    text-align: center;
}

.SearchButtons {
    background: #25AAE1;
    width: 160px;
    font-weight: bold;
}

.JobsType-Container{
    display: flex;
    flex-direction: row;
   
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.JobsArray{
    display: flex;
    flex-direction: row;
  
    flex-wrap: wrap;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.JobSecondLayer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    padding: 15px;
    margin: 20px;
    color: white;
    font-family: 'Neue Haas Grotesk Display Pro';
    animation: slideIn 0.5s ease-out;
    font-size: 13px
}

.Input {
    background: #EBFBFF;
    border: 1px solid black;
    border-radius: 6px;
    text-align: center;
    width: 360px;
    height: 30px;
    margin: 10px;
}

.AdvanceSearch {
    background-color: white;
    width: 165px;
    color: black;
    border: 1px solid black;
    font-weight: bold;

}




.inline-block-container{
    display: flex;
    vertical-align: middle;
    margin: 0; 
}

.JobTypeButton {
    background: #D2F4F4;
    padding: 6px;
    color: #A6A6A6;
    width: 120px;
    border-radius: 8px;
    margin: 14px;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 12px;
    line-height: 17.68px;
    letter-spacing: 0.03em;
    text-align: center;


}

.JobTypeButtons {
    background: #D2F4F4;
    padding: 6px;
    color: black;
    width: 120px;
    border-radius: 8px;
    margin: 14px;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 12px;
    line-height: 17.68px;
    letter-spacing: 0.03em;
    text-align: center;


}

.Checkbox{
    background: #25AAE1;
}

.JobsMeetlogo {
    max-width: 370px;
}

.JobsThirdLayer {
    background: #EBFBFF;
    padding: 40px;
   

}

.JobsCategory-Container {
    display: flex;
}

.JobsCategory-Item {
    text-decoration: underline;
   
    font-size: 15px;
    
}

.JobsFourthLayer {
    background: #25AAE1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: white;
   
}
.ViewJobsButton{
    background: #25AAE1;
    color: white;
    border: 1px solid white;
    width: fit-content;
    text-align: center;
}
.AgilewitJobsLogo{
    height: 400px;
   }

   .Volunteen-Info{
    display: flex;
    flex-direction: column;
    align-self: center;
   }

.Filter-Buttons{
   
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  
}

.FilterButton-Items{
    width: fit-content;
    margin: 10px;
}


@media screen and (min-width:767px) {
    .JobTopLayer {
        background: #EBFBFF;
        padding: 40px;
    }
    
    .JobSearchContainer {
        display: flex;
        flex-direction: row;
        }
    
    .SearchButtons {
        background: #25AAE1;
        width: 200px;
        font-weight: bold;
    }
    
    .Input {
        background: #EBFBFF;
        border: 1px solid black;
        border-radius: 6px;
        text-align: center;
        width: 360px;
        height: 30px;
    margin: 0px;
    }
    
    .AdvanceSearch {
        background-color: white;
        width: 200px;
        color: black;
        border: 1px solid black;
        font-weight: bold;
    
    }
    
 
    
    .JobTypeButton {
        background: #D2F4F4;
        padding: 6px;
        color: #A6A6A6;
        width: 230px;
        border-radius: 8px;
        margin: 10px;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
        font-size: 14px;
        line-height: 17.68px;
        letter-spacing: 0.03em;
        text-align: center;
    
    
    }
    
    .JobsMeetlogo {
        max-width: 800px;
    }
    
    .JobsThirdLayer {
        background: #EBFBFF;
        padding: 40px;
    
    }
    
    .JobsCategory-Container {
        display: flex;
    }
    
    .JobsCategory-Item {
        text-decoration: underline;
        margin: 10px;
        padding: 10px;
    }
    
    .JobsFourthLayer {
        background: #25AAE1;
        padding: 20px;
        display: flex;
        flex-direction: row;
        color: white;
       
    }
    .ViewJobsButton{
        background: #25AAE1;
        color: white;
        border: 1px solid white;
        width: fit-content;
        text-align: center;
    }
    .AgilewitJobsLogo{
        height: 400px;
       }
    
       .Volunteen-Info{
        display: flex;
        flex-direction: column;
        align-self: center;
       }
    
       @keyframes slideIn {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .JobSecondLayer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
        padding: 30px;
        margin: 10px;
        color: white;
        font-size: medium;
        font-family: 'Neue Haas Grotesk Display Pro';
        animation: slideIn 0.5s ease-out;
    }

    .JobTypeButton {
        background: #D2F4F4;
        padding: 6px;
        color: #A6A6A6;
        width: fit-content;
        border-radius: 8px;
        margin: 10px;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
        font-size: 16px;
        line-height: 17.68px;
        letter-spacing: 0.03em;
        text-align: center;
    
    
    }
    
    .JobTypeButtons {
        background: #D2F4F4;
        padding: 6px;
        color: black;
        width: fit-content;
        border-radius: 8px;
        margin: 10px;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
        font-size: 16px;
        line-height: 17.68px;
        letter-spacing: 0.03em;
        text-align: center;
    
    
    }


    .FiltersContainer{
        display: flex;
        flex-direction: column;
        color: black;
        
        
    }

    .Filter-Button{
        width: 100px;
        font-weight:600;
        background: #25AAE1;
        font-family: 'Neue Haas Grotesk Display Pro';
        height: fit-content;
        color: white;
    }
    
}