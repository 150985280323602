.Reset-TopLayer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}

.Forgot {
    padding: 10px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    border-radius: 6px;
}

.Reset-Input {
    text-align: center;
    width: 300px;
}

.Reset-Button {
    width: fit-content;
    display: flex;
    align-items: center;
}

.New-Password {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    border: solid 1px black;
    border-radius: 6px;
    padding: 10px;

}
.OTP-SentMsg{
    color: green;
    font-size: x-small;
}