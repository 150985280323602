.ServiceItemTop {
    display: flex;
    flex-direction: column;
    border: 1px solid #386097;
    padding: 10px;
    font-size: 15px;
   width: fit-content;
    margin: 10px;
  
}

.ServiceIcon {
    height: 60px;
    text-align: centey;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-self : flex-start;
    background: #DAE9FF;
    

    
}

.SeeMore {
    color: #25AAE1;
    text-decoration: underline;
    margin-top: 40px;

}



@media screen and (min-width:767px) {


    .ServiceItemTop {
        display: flex;
        flex-direction: column;
        border: 1px solid #386097;
        padding: 10px;
        margin: 10px;
        width: 140px;
        font-size: 14px;
        justify-content: space-between;

        /* Aligns flex items vertically to the end */
    }

    .SeeMore {
        color: #25AAE1;
        text-decoration: underline;
        margin-top: 90px;

    }

}