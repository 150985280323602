.HomeTopLayer {
    color: black;
    
   
}

/* Intro Description for Mobile */
.IntroDescription {
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    width: 285px;
    margin-left: 53px;
    height: 102px;






}

/* Adjusting the Home Introduction Section for Mobile */
.HomeIntroduction {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #25AAE1;

    color: white;

}

.AgilewitGreyLogo {
    align-self: flex-end;
    height: 80px;
}

.Title {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-align: start;
}

.TitleHeading {

    font-size: 35px;

}

.TitleDescription {
    font-size: 14px;
    line-height: 18px;
    text-align: start;
}

.LetsWorkTogether {
    background-color: white;
    color: #25AAE1;
    text-align: center;
    font-size: 16px;
    padding: 8px;
    margin: 0 auto;
    width: 90%;
}

.HomePageDescription {
    padding: 20px;
    background: #EBFBFF;

}

.CompanyIdeology {
    font-size: 14px;
    margin-bottom: 20px;
}

.ShareYourIdeaTitle {
    font-size: 18px;
    width: 100%;
    text-align: center;
}

/* Client Assistance Section */
.ClientAssistance {
    font-size: 14px;
    text-align: center;
    margin: 10px auto;
}

@keyframes scroll {
    /* 0% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(-50%);
 } /* Adjust this value based on the width of your logos */


    from {
        left: 0px;
    }

    to {
        left: 200px;
    }
}


.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll 20s linear infinite;
    position: relative;
    
 width: 300px;
    height: 120px;
}


.ClientCompanyLogo {
   width: 50px;
   height: 50px;
    margin: 10px;
    filter: grayscale(100%);
}



.Services {
    display: block;
    text-align: center;
}

.ServicesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.AgilewitLogoBlue {
    display: none;
}

.Achievements {
    padding: 30px;
    background: #25AAE1;
    color: white;
    display: flex;
    flex-direction: row;
}

/* Partners Opinion Section */
.PartnersOpinion {
    padding: 30px;
    background: #EBFBFF;

}

.PartnersOpinionTitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.PartnerTitle {
    text-decoration: none;
    color: #25AAE1;

    font-size: 17px;
}

@media screen and (min-width: 767px) {
    .HomeTopLayer {
        display: flex;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }

    .IntroDescription {
        display: none;
    }

    .HomeIntroduction {
        padding: 40px;
        height: fit-content;
    }

    .ShareYourIdeaTitle {
        width: 400px;
    }

    .AgilewitGreyLogo {
        display: flex;
        align-items: flex-end;
        height: 100px;
    }

    .Title {
        font-size: 22px;
    }

    @keyframes scroll {
        /* 0% {
            transform: translateX(50%);
        }
        100% {
            transform: translateX(-50%);
     } /* Adjust this value based on the width of your logos */


        from {
            left: 0px;
        }

        to {
            left: 200px;
        }
    }


    .logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        animation: scroll 20s linear infinite;
        position: relative;
        width: fit-content;
        padding: 80px;
        height: 120px;
    }


    .ClientCompanyLogo {
        height: 90px;
        width: 100px;
        margin: 20px;
        filter: grayscale(100%);
    }

    .TitleHeading {
        color: white;
        width: 640px;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
        font-size: 75px;
        flex-wrap: wrap;
    }

    .TitleDescription {
        font-size: 20px;
        line-height: 27px;
    }

    .LetsWorkTogether {
        background-color: #25AAE1;
        color: white;
        font-size: 27px;
    }

    .HomeSecondLayer {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .DesktopOptionsIcon {
        display: flex;
        align-items: flex-end;
        height: 40px;
    }

    .SubHomeSecondLayer {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .HomeOptionsIcon {
        display: flex;
        margin: 10px;
        height: 150px;
        align-self: flex-end;
    }

    .CompanyIdeology {
        display: flex;
        gap: 90px;
        font-size: 19.5px;
    }

    .IdeaDescription {
        width: 800px;
        text-align: justify;
    }

    .ClientAssistance {
        width: 180px;
    }

    .ServicesList {
        display: flex;
        flex-direction: row;

        overflow-y: auto;
    }

    .Services {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-x: auto;
    }

    .AgilewitLogoBlue {
        display: flex;
        margin-top: 80px;
        height: 400px;
    }

    .Achievements {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        background: #25AAE1;
        padding: 40px;
    }

    .PartnersOpinion {
        background: #EBFBFF;
    }

    .PartnersOpinionTitle {
        font-size: 30px;
    }

    .PartnerTitle {
        text-decoration: none;
        color: #25AAE1;

        font-size: 30px;
    }

    .PartnerListForDesktop {
        display: flex;
    }

    .PartnerListForMobile {
        display: none;
    }
}