.MobileGooglemap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EBFBFF;
    padding: 20px;

}

.Email{
    max-width: 380px;
}

.FirstName{
    max-width: 174px;
    
}


.MobileFirstName {
    background: #EBFBFF;
    border: 1.4px solid #08c4f3;
    padding: 8px;
    margin: 8px;
    width: 330px;
    height: 50px;
}

.MobileEmail {
    background: #EBFBFF;
    border: 1.4px solid #08c4f3;
    padding: 8px;
    margin: 8px;
 width: 330px;
    height: 50px;
}

.MobileMessage {
    background: #EBFBFF;
    border: 1.4px solid #08c4f3;
    padding: 8px;
    margin: 8px;
    width: 330px;
    height: 100px;
}

button {

    padding: 9px;
    background-color: #08c4f3;
    color: white;
    border: 0 solid white;
    font-size: 10px;
    border-radius: 6px;
    width: 360px;
}

.MobileForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.MapFrame {
    width: 340px;
}

.MobileFirstName::placeholder {
    color: #08c4f3; 
}
.MobileEmail::placeholder {
    color: #08c4f3; 
}
.MobileMessage::placeholder {
    color: #08c4f3; 
}

@media screen and (min-width:767px) {
    .ContactUsforMobileTopLayer {
        display: none;
    }
}