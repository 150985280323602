

.Employ-Details {
    border: solid 1px black;
    padding: 10px;
    border-radius: 6px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    
}

.New{
    color: blue;
    text-decoration: none;
    font-size: 10px !important;
}

.SearchBar{
    text-align: center;
}

.Topping{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SubmitButton{
    margin: 10px;
}

.Week-Button{
    display: flex;
    justify-content: space-between;
    font-size: 10px;
}

.WeekButton{
    width: fit-content;
    margin: 10px;
}


.WarningMsg {
    color: red;
    text-align: center;
}

.TimeSheet-Input {
    background: #EBFBFF;
    display: flex;
    flex-direction: row;
   justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: solid 1px black;
    
}

.Time-Entry{
    width: 40px;
}

.AdminLogo {
    height: 120px;
    display: flex;
}

.Employee-Details {
    border: solid 1px black;
    padding: 10px;
    border-radius: 6px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 20px;
    
    
}

.Login-Button {
    width: fit-content;
    padding: 10px;
    margin: 10px;
    display: flex;
    align-self: center;
    font-weight: bold;
    text-decoration: none;
  
}

.Dash-Board{
    display: flex;
    flex-direction: column;

}

.EmploysInfoContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Arrow{
    height: 30px;
}
.Registration-Button{
    text-decoration: none;
    color: black;
    font-size: 14px;
}
.DashBoard-FirstLayer{
    background-color: lightgreen;
    font-size: 17px;
    padding: 20px;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    text-align: center;
    height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EmploysInfoTag{
    font-size: 17px;
}
.Admin-green{
    height: 100px;
}
.Input-Value{
    text-align: center;
    margin: 10px;
}
.Employs-Personal-Info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #EBFBFF;
    padding: 30px;
}
.Profile-Info{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LogIn-Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (min-width:767px) {
    .WarningMsg {
        color: red;
        text-align: center;
    }
    
    .TimeSheet-Input {
        background: #EBFBFF;
        display: flex;
        flex-direction: row;
      justify-content: space-between;
        align-items: center;
        padding: 20px;
       
        
    }
    
    .AdminLogo {
        height: 400px;
        display: flex;
    }
    
    .Employee-Details {
        border: solid 1px black;
       text-align: center;
        padding: 10px;
        border-radius: 6px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-right: 80px;
        
    }
    
    .Login-Button {
        width: fit-content;
        padding: 10px;
        display: flex;
        align-self: center;
        font-weight: bold;
      
    }
    .Employs-Personal-Info{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #EBFBFF;
        padding: 30px;
    }
    .Profile-Info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .LogIn-Container{
        display: flex;
        flex-direction: row;
    
       
    }
    .Dash-Board{
        display: flex;
        flex-direction: row;
    
    }
    .StartWorking-Button{
width: fit-content;
margin: 10px;
display: flex;
margin-right: auto;

    }
    .StopWorking-Button{
        width: fit-content;
margin: 10px;
display: flex;
margin-left: auto;
    }
}