.DeskTopAppleAnimation{
    background: #25AAE1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

} 

.DesktopAppDevelopmentModels{
    background-color: white;
    border-radius: 6px;
    display: flex;
    padding: 20px;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
 
 .AppleLogos{
    height: 200px;
    
    
 }
 .AppleLogo{
    max-width: 350px;
 }


 .DeskTopAppleAnimationLayerTwo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }
.DeskTopAndroid{
    background-color: white;
   border-radius: 6px;
    padding: 20px;
    font-size: 18px;
    margin: 10px;
    width: 350px;
   
}

 .DesktopCapsLets{
    color: #25AAE1;
    background-color: white;
    padding: 10px;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 17.22px;
    letter-spacing: 0.03em;
    text-align: left;
 }

.AppGallery{
    background: #EBFBFF;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 40px;
}





@media screen and (max-width:767px) {
    .MobileAppDevelopmentDesktopViewTopLayer{
        display: none;
    }
}