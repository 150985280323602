
.TrainingTopLayer{
    height: 100vh;
    overflow: auto;
    width: 100vw;
    background: #EBFBFF;
    display: flex;
    flex-direction: column;

   
}

.TrainingLogos{
    width: 90%;
   margin: 20px;
}
.TrainingTitle{
    text-align: center;
    font-size: 15px;
    margin: 10px;
}



.BottomTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    background: #25AAE1;
    padding: 8px;
    width: fit-content;
    color: white;
    margin: 10px;

} 


@media screen and (min-width:767px) {
  
.BottomTitle{
    text-align: center;
    color: black !important;
    background: #EBFBFF;
    text-align: center;
   display: flex;
   align-self: center;

}
}