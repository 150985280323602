.DropDownProfile {
  position: fixed;
  right: 0.5rem;
  font-size: 11px;
  height: 100vh;
  padding: 30px;

  text-decoration: none;
  background: rgba(50, 45, 45, 1);
  color: white;
  display: flex;
  flex-direction: column;
  animation: fadeInDown 0.5s ease forwards;
}

.DropDownIcone {
  display: flex;
  align-self: flex-end;
  height: 40px;
  width: 30px;
}
.DropDownIconContainer{
  display: flex;
}


.DropDownSecondLayer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.DropDownFirstLayer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 15px;
  padding: 20px;
  padding-top: 100px;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

li {
  list-style-type: none;
  font-family: Georgia, 'Times New Roman', Times, serif;
  gap: 10px;
}

.LetsWorkTogetherContainer {
  display: flex;
  align-items: flex-end;
 
}

.LetWorktogethers {
  color: #00B7F0;
  text-decoration: none;
}

.LinkRemove {
  text-decoration: none;
  color: white;
  display: flex;
}


@media screen and (min-width:767px) {
  .DropDownProfile {
    position: fixed;
    right: 0.5rem;
    width: 650px;
    height: 100vh;
    padding: 60px;
    text-decoration: none;
    background: rgba(50, 45, 45, 1);
    color: white;
    display: flex;
    font-size: 16px;
    flex-direction: column;
    animation: fadeInDown 0.5s ease forwards;
  }

  .DropDownIcone {
    display: flex;
    align-self: flex-end;
    height: 30px;
    width: 30px;
  }

  .Links {
    text-decoration: none;
    color: black;
  }

  .DropDownSecondLayer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .DropDownFirstLayer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-top: 0px
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  li {
    list-style-type: none;
    font-family: Georgia, 'Times New Roman', Times, serif;
    gap: 10px;
  }

  .LetsWorkTogetherContainer {
    display: flex;
    align-items: flex-end;
  }

  .LetWorktogether {
    color: #00B7F0;
    text-decoration: none;
  }

  .LinkRemove {
    text-decoration: none;
    color: white;
    display: flex;
  }
}