.BottomPageTopLayer {
    background: #322D2D;
    padding: 20px;
    flex-wrap: wrap;
    color: white;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}

.BottomPageTopOne {
    background: #322D2D;
}

.Links {
    text-decoration: none;
    color: white !important;
}

.FinalLineContent {
    color: white;
    margin: 30px;
    margin-bottom: 40px;
}

.ServiceRow {
    display: flex;
    flex-wrap: wrap;
    font-size: 12.3px;
    gap: 15px;
    padding: 10px;
}

.SocialMediaIcons {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    height: 30px;
}

.BottomPageFirstLayer {
    display: flex;
    justify-content: space-between;
}

.AgilewitBottomPageLogo {
    height: 60px;
}

.CONTACT {
    width: 200px;
}

.InfoMail {
    color: #25AAE1;
    
}

.BottomPageLine {
    background-color: grey;
}

@media screen and (min-width: 767px) {
    .BottomPageTopLayer {
        display: flex;
        flex-direction: column;
        background: #322D2D;
        padding: 40px;
        color: white;
    }

    .BottomPageFirstLayer {
        display: flex;
        justify-content: flex-start;
        gap: 80px;
    }

    .AgilewitBottomPageLogo {
        height: 100px;
    }

    .BottomPageServices {
        font-size: 15px;
        margin: 20px;
    }

    .CONTACT {
        width: fit-content;
    }

    .ServiceRow {
        flex-wrap: nowrap;
    }

  
}
