    .ConsultingTopLayer {
        background: #EBFBFF;

    }

    ul {
        list-style-type: disc;
    }

    li {
        list-style-type: disc;
    }

    .ConsultingItems {
        background: #25AAE1;
        margin: 5px;
        padding: 10px;
        color: white;
        font-size: 6px;
        overflow-x: auto;
        display: flex;
        justify-content: space-between;
       
    }

    .ConsultingTypeTopLayer {
        display: flex;
        flex-direction: column;
        
        padding: 30px;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    }

    .ConsultingLogo {
        display: flex;
        align-self: center;
        width: 300px;
    }





    .OracleSecondLayer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .SecondLayerTitle {
        color: #25AAE1;
    }

    .OraclSecondLayer {
        display: flex;
        flex-direction: column;

        justify-content: space-around;

    }


    @media screen and (min-width: 767px) {
        .ConsultingTopLayer {
            background: #EBFBFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;

        }

        .ConsultingItems {
            background: #25AAE1;
            margin: 20px;
            padding: 20px;
            color: white;
            display: flex;
            justify-content: space-between;
            font-size: 15px;
        }

        .OracleSecondLayer {
            display: flex;
            flex-direction: row;
            gap: 30px;
        }
        .ConsultingLogo {
            display: flex;
    align-self: center;
            width: fit-content;
        }
    
    }