.AgilewitLogo {
    height: 40px;
    margin-left: 10px;
}

.HeaderFirstLayer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    
}

.OptionsIcon {
    margin-top: 15px;
    height: 50px;
    width: 70px;
}




@media screen and (min-width: 767px) {
    .HeaderFirstLayer {
        display: none;
    }

    .HeaderTop {
        display: none;
    }
}