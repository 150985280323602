.StaffContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #EBFBFF;
}

.StaffDescription {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    font-size: 16px;
    text-align: justify;
    font-weight: 500;
    line-height: 25.12px;
    letter-spacing: 0.03em;
    margin: 20px;
}

.MissionInfo {
    color: white;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    font-size: 12px;
    text-align: unset;
}

.Missile {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.MissionContainer {
    display: flex;
    background: #25AAE1;
    padding: 40px;
    
}
.MissionItems{
    display: flex;
    flex-direction: column;
    background: #25AAE1;
    
    flex-wrap: wrap;
   color: white;
   padding: 20px;
}

@media screen and (min-width: 769px) {
    .StaffContainer {
        background: #EBFBFF;
        padding: 40px;
        display: flex;
        flex-direction: row;
        gap: 60px;
        justify-content: space-between;
    }
.MissionDescription{
    font-size: 20px;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}
    .StaffDescription {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        font-family: "Neue Haas Grotesk Display Pro", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 25.12px;
        letter-spacing: 0.03em;
        margin: 20px;
    }
    .MissionItems{
        background: #25AAE1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       color: white;
       padding: 40px;
    }
}
