.ConsenTopLayer {
    height: 100vh;
    overflow-y: auto;
    width: 100vw;
}

.ConsenSecondLayer {
    background: #EBFBFF;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.ConsenContent {
    width: fit-content;
}

.ConseThirdLayer {
    background: #25AAE1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    color: white;
}

ul {
    list-style-type: disc;
}

li {
    list-style-type: disc;
}

.ConsenList {
    width: fit-content;
    font-size: medium;
    display: flex;
    flex-direction: column;
   
}

.UploadDocument {
    width: 100%;
    max-width: 340px;
    
}


.LoginPic {
    max-width: 340px;
    margin-bottom: 20px;
}


@media screen and (min-width:767px) {
    .ConsenTopLayer {
        height: 100vh;
        overflow-y: auto;
        width: 100vw;
    }

    .ConsenSecondLayer {
        background: #EBFBFF;
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .ConsenContent {
        width: 350px;
        text-align: justify;
        font-size: medium;
    }

    .ConseThirdLayer {
        background: #25AAE1;
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        color: white;
    }

    ul {
        list-style-type: disc;
    }

    li {
        list-style-type: disc;
    }

    .ConsenList {
        width: 400px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-self: end;
    }

    .UploadDocument {
        width: 400px;
    }
}